import { placeProject } from './project'
import request from './request'
import { QueryPageDto } from './dto'
// 首页 列表 余额 在保人数 接口 /rest/period-insur/company/period-data-port
export function getInsureData(): Promise<any> {
  return request.get('/rest/period-insur/company/period-data-port')
}

// 首页保险详情页面 方案详情 -（总人数，当前在保，本月保费等）/rest/period-insur/company/plan-info
export function getInsureDetailInfo(data: {
  planId: string | string[],
  month: string
}): Promise<any> {
  return request.get('/rest/period-insur/company/plan-info', {
    params: data
  })
}

// 方案详情 - 加减记录（方案id） /rest/period-insur/operate-record/list-plan
export function getInsureDetailAdditionList(data: {
  pageSize: number
  pageNum: number
  companyPlanId: string | number | string[]
  insurStartTime?: string
  insurStartDate?: string
}): Promise<any> {
  return request.get('/rest/period-insur/operate-record/list-plan', {
    params: data
  })
}

// 方案详情 - 加减记录导出（加减记录id）
export function getInsureDetailAdditionExport(data: {
  id: string | number
}): Promise<any> {
  return request.get('/rest/period-insur/operate-record/export-info', {
    params: data
  })
}

// 方案详情 - 人员名单（方案id） /rest/period-insur/staff-record/list-plan
export function getInsureDetailPersonnelList(data: {
  pageSize: number
  pageNum: number
  companyPlanId: string | number | string[]
  insurStartTime: string
  policyStatus?: number | string
}): Promise<any> {
  return request.get('/rest/period-insur/staff-record/list-plan', {
    params: data
  })
}

// 获取被派遣单位列表
export function getDispatchList(data: {
  pageSize: number
  pageNum: number
  searchText?: string
  status?: number
  insurCompany?: string,
  planCode?: string,
  isGetFile: any,
  isGetArea?:any
}): Promise<any> {
  return request.get('/rest/period-insur/serve-company/list', {
    params: data
  })
}

// 提交被派遣单位 /rest/period-insur/serve-company/add
export function addDispatch(data: {
  serveCompanyId: number
  params: { video: string }
}): Promise<void> {
  return request.post('/rest/period-insur/serve-company/add', data)
}

// 获取被派遣单位
export function getDispatchExamine(data: {
  pageSize: number
  pageNum: number
  status: any
}): Promise<any> {
  return request.get('/api/v1/pay/period-insur/serve-company/info', {
    params: data
  })
}

// 派遣段位 取消 审核 接口 /rest/period-insur/serve-company/cancelAudit
export function cancelDispatchExamine(data: { id: string }): Promise<void> {
  return request.post('/rest/period-insur/serve-company/cancelAudit', data)
}

// 派遣单位 删除 接口 /rest/period-insur/serve-company/delete
export function deleteDispatchExamine(data: { id: string }): Promise<void> {
  return request.post('/rest/period-insur/serve-company/delete', data)
}

// 获取单位列表 rest/pay/serve-company/list-to-pay
export function getCompanyList(): Promise<any> {
  return request.get('/rest/pay/serve-company/list-to-pay')
}

// 被派遣单位列表信息 /rest/period-insur/serve-company/info
export function getCompanyDetailList(data: {
  pageNum: number
  pageSize: number
  status: number
}): Promise<any> {
  return request.get('/rest/period-insur/serve-company/info', {
    params: data
  })
}

// 获取员工人员信息 /rest/period-insur/staff-record/list-staff
export function getStaffRecordList(data: {
  companyPlanId?: number
  pageNum: number
  pageSize: number
  policyStatus?: string | number
  searchText: string
  insurCompany: string
  insurValidFlag: boolean
  insurEmptyEndDateFlag?: boolean,
  insurExistEndDateFlag?: boolean
}): Promise<any> {
  return request.get('/rest/period-insur/staff-record/list-staff', {
    params: data
  })
}

export function getPeopleList(params: {
  pageNum: number
  pageSize: number
  searchText: string
  policyStatus?: string
}): Promise<any> {
  return request.get('/rest/period-insur/staff-record/list-staff', {
    params
  })
}

export function getPeopleListcopy(params: {
  pageNum: number
  pageSize: number
  searchText: string
  policyStatus?: string
}): Promise<any> {
  return request.get('/rest/period-insur/staff-record/out-danger-list-staff', {
    params
  })
}
export function getPeopleRecord(params: {
  pageNum: number
  pageSize: number
  idCardNo: string
}): Promise<any> {
  return request.get('/rest/period-insur/staff-record/info', {
    params
  })
}

// 投保 - 人员加保 /rest/period-insur/staff-record/ensure
export function insureAdd(data: any): Promise<any> {
  return request.post('/rest/period-insur/staff-record/ensure', data)
}

// 投保 减保前需要做校验 /rest/period-insur/staff-record/worker-insur-info
export function insureCheckReduce(data: any): Promise<any> {
  return request.post(
    '/rest/period-insur/staff-record/worker-insur-info',
    data
  )
}

// 减保 /rest/period-insur/staff-record/terminal-ensure
export function insureReduce(data: any): Promise<void> {
  return request.post('/rest/period-insur/staff-record/terminal-ensure', data)
}

// 报案理赔 - 列表 /rest/period-insur/insur-period-report-case/list
export function getReportList(params: {
  pageNum: number
  pageSize: number
  searchText: string
  uploadStatus?: any
}): Promise<any> {
  return request.get('/rest/period-insur/insur-period-report-case/list', {
    params
  })
}

// 提交新增报案 /rest/period-insur/insur-period-report-case/report-case
export function submitReport(data: any): Promise<void> {
  return request.post(
    '/rest/period-insur/insur-period-report-case/report-case',
    data
  )
}

// 保险详情导出全部  /rest/period-insur/ensure-cost/export
export function insureDetailAllExport(params: {
  queryMonth: string
  queryType: string
  companyPlanId: any
}): Promise<any> {
  return request.get('/rest/period-insur/ensure-cost/export', {
    params
  })
}

// 导出每日被保人员账单
export function exportperiodbill(params: {
  effectiveDate: string
  companyPlanId: any
}): Promise<any> {
  return request.get('/rest/period-insur/ensure-cost/export-period-bill', {
    params
  })
}

// 查询账单列表
export function getEnsureCostListapi(params: {
  queryMonth: string
  pageSize: string | number
  pageNum: string | number
}): Promise<any> {
  return request.get('/rest/period-insur/ensure-cost/list', {
    params
  })
}

// 报案详情页面信息 /rest/period-insur/insur-period-report-case/out-danger-detail
export function reportDetailInfo(params: {
  id: string | string[]
}): Promise<any> {
  return request.get(
    '/rest/period-insur/insur-period-report-case/out-danger-detail',
    {
      params
    }
  )
}

// 保费趋势
export function getMoneyTrend(
  params: void
): Promise<{
  ensureDay: string
  money: number
  peopleCount: number
}[]> {
  return request.get('/rest/period-insur/ensure-cost/insur-cost-trend', {
    params
  })
}

export interface PlanStatisticsDto {
  occupationGroup: { count: number; occupationName: string }[]
  serveCompanyGroup: { count: number; serveCompanyName: string }[]
}

// 首页人员统计
export function getPeopleStatistics(params: {
  policyStatus: string // 1 或 ''
}): Promise<PlanStatisticsDto> {
  return request.get('/rest/period-insur/staff-record/statistics-plan-all', {
    params
  })
}

// 保险详情人员统计
export function getPlanStatistics(params: {
  companyPlanId: string
  policyStatus: string
}): Promise<PlanStatisticsDto> {
  return request.get('/rest/period-insur/staff-record/statistics-plan', {
    params
  })
}

// 长期保-员工查询-导出名单接口
export function getDerivationRoster(params: {
  searchText: string,
  policyStatus: number
}): Promise<any> {
  return request.get('/rest/period-insur/staff-record/list-staff-export', {
    params
  })
}

// 多场景异常接口返回状态
export interface ResDto {
  code: number
  msg: string
}

// 校验身份证
export interface IdCardNoVerifyDto {
  companyPlanId?: number,
  workerInfoList: [{
    index?: number | undefined,
    userName: string,
    idCardNo: string | number,
    msg?: string | undefined
  }]
  // userName: string
  // idCardNo: string
  // msg?: string
  // index?: number
}

export interface IdCardNoVerifyDtoTwo {
  index?: number | undefined,
  userName: string,
  idCardNo: string | number,
  msg?: string | undefined
}

export interface ReIdCardNoVerifyDto {
  correctList: [],
  errorList: [{ userName: string, idCardNo: string, errMsg: string }]
}

// userName: string
// idCardNo: string
// msg?: string
// index?: number

export interface addListInformation {
  occupationName?: string
  serveCompanyName: string
  userName: string
  serveCompanyId?: number | string
  idCardNo: string
  occupationId?: number | undefined | string
  occupationList?: any
  joinType?: number | null
  level?: number | string | undefined
  index?: number | null
  errMsg?: string
}

export interface IdCardNoVerifyResDto extends ResDto {
  data: ReIdCardNoVerifyDto
}

export function checkoutIdCardNoList(
  data: IdCardNoVerifyDto
): Promise<ReIdCardNoVerifyDto> {
  return request.post('/rest/period-insur/staff-record/worker-insur-info', data)
}

export function checkoutIdCardNoListTwo(
  data: IdCardNoVerifyDtoTwo[]
): Promise<any> {
  return request.post('/rest/common/batchIdCardNoVerify', data)
}

// 检测续保条件
export function testingInsure(
  data: {
    companyPlanId: number | string,
    insurStartDay: string,
    staffList: any
  }
): Promise<{
  price?: number,
  priceFour?: number,
  priceFive?: number,
  needCreateCount?: number,
  deductionCount?: number,
  needCostFeeMonth?: number,
  tomorrowNeedCostFee?: number,
  currentBalance?: number,
  willEnsureCostFee?: number,
  errorList?:any,
  isSuccess: boolean
}> {
  return request.post('/rest/period-insur/staff-record/ensure-before-check', data)
}

// 检测是否月底
export function renewalMonthNotify(data: {
  planId: string | string[]
}): Promise<any> {
  return request.get('/rest/period-insur/staff-record/onekey-renewal-month-notify', {
    params: data
  })
}

// 一键换厂 /rest/period-insur/staff-record/modify-serve-company
// eslint-disable-next-line no-use-before-define
export function oneKeyInsteadFactory(data: disposableTransposing
): Promise<any> {
  return request.post('/rest/period-insur/staff-record/modify-serve-company',
    data
  )
}

export interface disposableTransposing {
  'insurStartDay'?: string,
  'staffList': [
    // eslint-disable-next-line no-use-before-define
    disposableTransposingArr
  ]
}

export interface disposableTransposingArr { // 输出接口BillDto的接口
  'id': number, // 要换厂的人员列表id
  'idCardNo': string, // 人员身份证
  'occupationId': number, // 工种id（客户要换厂的工种id）
  'occupationName': string, // 工种名（客户要换厂的工种名）
  'serveCompanyId': number, // 被派遣单位id（要换厂的id）
  'serveCompanyName': string, // 被派遣单位名称（要换厂的名称）
  'userName': string, // 用户名
  'insurStartDay'?: string | undefined

}

export function onLongInsuranceRevoke(data: {
  id: number,
  companyPlanId: number
}): Promise<any> {
  return request.post('/rest/period-insur/operate-record/revoke-ensure',
    data
  )
}

// 长期保工对工充值 接口
export function addLongTermRecharge(data: any): Promise<any> {
  return request.post('/rest/company/period-recharge',
    data
  )
}

// 查询长期保  在保人员名单列表接口
export function longInsureRecord(data: any): Promise<any> {
  return request.get('/rest/period-insur/staff-record/query-insur-user', {
    params: data
  })
}

// 获取长期保公司保险方案
export function longQueryPlans(): Promise<any> {
  return request.get('/insur/InsurComCompanyPlans/query-plans')
}

// 获取长期保 充值记录列表
export function longBillRecord(data: QueryPageDto): Promise<any> {
  return request.get('/rest/company/period-recharge-list', {
    params: data
  })
}

// 检测保险是否可以批量投保
export function checkStatusPeriod(): Promise<any> {
  return request.get('/insur-com-company/insur-com-company/check-status-period')
}

// 获取被派遣单位详情信息
export function getOccupationDetailById (id: number): Promise<any> {
  return request.get(`/period-insur/serve-company/query-svc-occ/${id}`)
}

// 获取被派遣单位详情信息
export function applyOccupation (data: any): Promise<any> {
  return request.post('/rest/period-insur/serve-company-occupation', data)
}

// 撤销工种记录
export function deleteOccupation (id: number): Promise<any> {
  return request.delete(`/rest/period-insur/serve-company-occupation/${id}`)
}

// 申请工种记录列表
export function getOccupationList (data: any): Promise<any> {
  return request.get('/rest/period-insur/serve-company-occupation/list', {
    params: data
  })
}
